body {
  margin: 0;
  padding: 0;
  background: black;
  color: white;
  overflow: hidden;
}

#app {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
